<template>
  <div class="footer">
    <van-divider>©六合宝典 版权所有 v1.0 </van-divider>
    <p>打造全网最干净的六合资讯网</p>
    <p class="time">本站已运行{{state.Y}}年{{state.M}}月{{state.D}}日{{state.H}}时{{state.m}}分{{state.s}}秒</p>
  </div>
</template>

<script setup>
import { calculateTimeDifference } from "@/utils/index";
import { reactive } from "vue";
const state = reactive({
  Y: "",
  M: "",
  D: "",
  H: "",
  m: "",
  s: ""
});

setInterval(() => {
  const time = calculateTimeDifference(2021, 8, 1, 12, 0, 0);
  state.Y = time.years;
  state.M = time.months;
  state.D = time.days;
  state.H = time.hours;
  state.m = time.minutes;
  state.s = time.seconds;
}, 1000);
</script>

<style lang="scss" scoped>
.footer{
    padding: 16px 0;
    text-align: center;
    .time{
        color: red;
    }
}
</style>