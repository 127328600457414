import { createRouter, createWebHashHistory } from "vue-router";
import Home from "@/pages/home.vue";
import nprogress from 'nprogress'

const routes = [
    { path: "/", name: "Home", component: Home, meta: { keepAlive: true } },
    {
        path: '/drawList',
        name: 'drawList',
        component: () => import('@/pages/drawList.vue')
    },
    {
        path: '/typeTablePage',
        name: 'typeTablePage',
        component: () => import('@/pages/typeTablePage.vue')
    },
    {
        path: '/plan',
        name: 'plan',
        component: () => import('@/components/plan/plan.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


router.beforeEach((to, from, next) => {
    nprogress.start();
    next()
})

router.afterEach((to, from, next) => {
    nprogress.done();
    // next()
})

export default router