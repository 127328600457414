import axios from 'axios'
import { showLoadingToast, closeToast } from 'vant';


export function createRequets(baseURL, responseCode, errorCode) {
    const request = axios.create({
        timeout: 1000 * 60,
        baseURL
    })

    request.interceptors.request.use((config) => {
        showLoadingToast({
            message: '...加载中',
            forbidClick: true,
            duration: 0
        });
        return config
    })

    request.interceptors.response.use(
        (res) => {
            closeToast()
            let { data } = res
            if (responseCode && res.data.code == responseCode) {
                data = res.data.data
            }
            return data
        },
        (err) => {
            closeToast()
            console.log(err, '请求出错');
        }
    )

    return request
}

const api = 'http://aocai88.top'
// const api = '/aaa'
export const drawHttp = createRequets(api, 10000)
export const myHttp = createRequets(api)
// http://www.baiao.xyz
// /aaa