<template>
  <div class="plan_item">
    <div class="plan_item_advertisement">
      <!-- <img :src="banner" /> -->
      <img src="https://www.7859888tp.com/750-50.gif" />
    </div>
    <p class="plan_item_title">
      <span>【精选6肖】</span>
      <img :src="top" alt="" />
    </p>
    <ul>
      <li v-for="(el, i) in data.list" :key="i" :class="{ li_win: el.ifWin }">
        <span class="qi">{{ el.qiShu }}期-</span>
        <span class="name">{{ data.title }}:</span>
        <div :class="{ suck: !el.ifWin && !el.ifZuiXin }">
          <span class="value" v-if="typeof el.yuCe == 'string'">
            【 <span :class="{ win: el.ifWin }">{{ el.yuCe }}</span> 】
          </span>
          <span class="value" v-else>
            【
            <span
              :class="{ win: num.ifWin }"
              v-for="(num, numIndex) in el.ifZuiXin ? el.yuCe : el.shuXingList"
              :key="numIndex"
              >{{ num.yuCe || num }}</span
            >
            】
          </span>
        </div>
        <span class="winText" v-if="el.ifZuiXin">等开</span>
        <span class="winText" v-else>{{ el.ifWin ? "准" : "错" }}</span>
      </li>
    </ul>
  </div>
</template>
  
  <script setup>
 
import top from "@/assets/img/top.gif";
import { toRefs } from "vue";

const props = defineProps({
  data: Object,
});

const { data } = toRefs(props);
</script>
  
  <style lang="scss" scoped>
.title {
  text-align: center;
  background-color: #009788;
  font-size: 25px;
  font-weight: bold;
  padding: 6px 0;
}
.plan_item {
  border: 1px solid #009788;
  .plan_item_advertisement {
    img {
      width: 100%;
      height: 40px;
      display: block;
    }
  }
  .plan_item_title {
    font-weight: bold;
    text-align: center;
    background-image: linear-gradient(to bottom, #b4b9b1, #f1edeb);
    padding: 3px 0;
    border-top: 2px solid #767474;
    border-bottom: 2px solid #767474;
    img {
      width: 30px;
      height: 30px;
    }
  }
  ul {
    background-color: #eee;
    li {
      text-align: center;
      padding: 5px 12px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      .value {
        color: red;
        .win {
          background-color: #ffff00;
        }
      }
      &:not(:last-child) {
        border-bottom: 2px solid #d2d2d2;
      }
    }
    .li_win {
      .winText {
        color: #d00880;
      }
    }
    .suck {
      text-decoration-line: line-through;
    }
  }
}
</style>