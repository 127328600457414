<template>
  <ul class="mybox">
    <p class="mytitle">澳門优秀广告站收录</p>
    <li v-for="(item, index) in aomen_shoulu" :key="index">
      <a :href="item.url" target="_blank">{{ qi }}期: {{ item.title }}</a>
    </li>
  </ul>
</template>

<script setup>
import { aomen_shoulu } from "@/utils/index";
import { drawHttp } from "@/request";
import { ref } from "vue";
const qi = ref("");
function loadData() {
  console.log("123456");
  drawHttp
    .get(`/gallerynew/h5/index/lastLotteryRecord/V2?lotteryType=2`)
    .then((result) => {
      qi.value = result.nextLotteryNumber;
    })
    .catch((err) => {
      console.log(err);
    });
}
loadData();
</script>

<style lang="scss" scoped>
ul {
  margin-top: 3px;
  border-color: #767474 !important;
  p {
    font-size: 20px;
  }
  li {
    padding: 4px 0;
    border: 1px solid #ccc;
    a {
      color: #2f00ff;
      font-size: 16px;
      font-weight: bold;
      padding: 0 12px;
    }
  }
}
</style>