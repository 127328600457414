<template>
  <div class="head">
    <!-- <div class="left">
      <img src="@/assets/img/head/logo.gif" alt="" />
    </div>
    <div class="right">
      <img src="@/assets/img/head/zuikuai.png" alt="" />
    </div>-->
    <div class="left"></div>
    <div class="title">
      <img :src="logo" alt />
    </div>
    <div class="right">
      <img :src="typeTableIcon" alt @click="router.push({name:'typeTablePage'})" />
    </div>
  </div>
</template>

<script setup>
import typeTableIcon from "@/assets/img/typeTableIcon.png";
import logo from "@/assets/img/logo.png";
import { useRouter } from "vue-router";
const router = useRouter();
</script>

<style lang="scss" scoped>
.head {
  width: 100vw;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  padding: 6px 10px;
  background-color: #fc9078;
  display: flex;
  flex: 1;
  justify-content: space-between;
  > div {
    width: 33.333%;
  }
  .title {
    text-align: center;
    img {
      height: 35px;
    }
  }
  .left {
  }
  .right {
    text-align: right;
    img {
      height: 35px;
      width: 65px;
    }
  }
}
</style>