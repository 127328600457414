import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import 'amfe-flexible' 
import router from '@/router/index'
import 'vant/es/toast/style';
import 'vant/es/image-preview/style';
import 'nprogress/nprogress.css' //样式必须引入
import { Lazyload } from 'vant';

createApp(App)
.use(router)
.use(Lazyload)
.mount('#app')
