export const fromList = new Map([
    ["01", { sx: '兔', wx: "金", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["02", { sx: '虎', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["03", { sx: '牛', wx: "土", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["04", { sx: '鼠', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["05", { sx: '猪', wx: "木", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["06", { sx: '狗', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["07", { sx: '鸡', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["08", { sx: '猴', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["09", { sx: '羊', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["10", { sx: '马', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["11", { sx: '蛇', wx: "水", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["12", { sx: '龙', wx: "水", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["13", { sx: '兔', wx: "木", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["14", { sx: '虎', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["15", { sx: '牛', wx: "火", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["16", { sx: '鼠', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["17", { sx: '猪', wx: "土", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["18", { sx: '狗', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["19", { sx: '鸡', wx: "水", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["20", { sx: '猴', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["21", { sx: '羊', wx: "木", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["22", { sx: '马', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["23", { sx: '蛇', wx: "金", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["24", { sx: '龙', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["25", { sx: '兔', wx: "土", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["26", { sx: '虎', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["27", { sx: '牛', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["28", { sx: '鼠', wx: "水", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["29", { sx: '猪', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["30", { sx: '狗', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["31", { sx: '鸡', wx: "金", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["32", { sx: '猴', wx: "金", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["33", { sx: '羊', wx: "土", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["34", { sx: '马', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["35", { sx: '蛇', wx: "木", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["36", { sx: '龙', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["37", { sx: '兔', wx: "火", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["38", { sx: '虎', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["39", { sx: '牛', wx: "金", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["40", { sx: '鼠', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["41", { sx: '猪', wx: "水", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["42", { sx: '狗', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["43", { sx: '鸡', wx: "木", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["44", { sx: '猴', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["45", { sx: '羊', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["46", { sx: '马', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["47", { sx: '蛇', wx: "土", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["48", { sx: '龙', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["49", { sx: '兔', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
])
export const fromList24 = new Map([
    ["01", { sx: '龙', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["02", { sx: '兔', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["03", { sx: '虎', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["04", { sx: '牛', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["05", { sx: '鼠', wx: "土", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["06", { sx: '猪', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["07", { sx: '狗', wx: "木", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["08", { sx: '鸡', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["09", { sx: '猴', wx: "火", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["10", { sx: '羊', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["11", { sx: '马', wx: "金", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["12", { sx: '蛇', wx: "水", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["13", { sx: '龙', wx: "水", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["14", { sx: '兔', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["15", { sx: '虎', wx: "木", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["16", { sx: '牛', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["17", { sx: '鼠', wx: "火", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["18", { sx: '猪', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["19", { sx: '狗', wx: "土", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["20", { sx: '鸡', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["21", { sx: '猴', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["22", { sx: '羊', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["23", { sx: '马', wx: "木", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["24", { sx: '蛇', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["25", { sx: '龙', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["26", { sx: '兔', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["27", { sx: '虎', wx: "土", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["28", { sx: '牛', wx: "水", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["29", { sx: '鼠', wx: "水", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["30", { sx: '猪', wx: "火", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["31", { sx: '狗', wx: "火", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["32", { sx: '鸡', wx: "金", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["33", { sx: '猴', wx: "金", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["34", { sx: '羊', wx: "土", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["35", { sx: '马', wx: "土", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["36", { sx: '蛇', wx: "木", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["37", { sx: '龙', wx: "木", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["38", { sx: '兔', wx: "火", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["39", { sx: '虎', wx: "火", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["40", { sx: '牛', wx: "金", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["41", { sx: '鼠', wx: "金", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["42", { sx: '猪', wx: "水", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["43", { sx: '狗', wx: "水", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["44", { sx: '鸡', wx: "木", bs: "绿波", hsds: "合数双", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
    ["45", { sx: '猴', wx: "木", bs: "红波", hsds: "合数单", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["46", { sx: '羊', wx: "火", bs: "红波", hsds: "合数双", color: 'red', numBg: new URL('@/assets/img/numBg/red.png', import.meta.url) }],
    ["47", { sx: '马', wx: "火", bs: "蓝波", hsds: "合数单", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["48", { sx: '蛇', wx: "土", bs: "蓝波", hsds: "合数双", color: 'blue', numBg: new URL('@/assets/img/numBg/blue.png', import.meta.url) }],
    ["49", { sx: '龙', wx: "土", bs: "绿波", hsds: "合数单", color: 'green', numBg: new URL('@/assets/img/numBg/green.png', import.meta.url) }],
])

export function getNumConfig(num, qishu = null) {
    if(qishu && qishu > 40){
        return fromList24.get(num)
    } else{
        return fromList.get(num)
    }
}

export function getNumSx(num, qishu = null) {
    return getNumConfig(num, qishu).sx
}
export function getNumWx(num, qishu = null) {
    return getNumConfig(num, qishu).wx
}
export function getNumBs(num) {
    return getNumConfig(num).bs
}
export function getNumColor(num) {
    return getNumConfig(num).color
}
export function getNumBg(num) {
    return getNumConfig(num).numBg.href
}

export function calculateTimeDifference(year, month, day, hour, minute, second) {
    var currentDateTime = new Date();
    var givenDateTime = new Date(year, month - 1, day, hour, minute, second);
    var timeDifference = currentDateTime - givenDateTime;

    var years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
    var months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30) % 12);
    var days = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) % 30;
    var hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
        years: years,
        months: months,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
}

export const aomen_shoulu = [
    {
        title: '澳彩广东会【特码，平特，波色】全有',
        url: 'https://g529dh.loremagazine.com:2053/#am43229'
    },
    {
        title: '澳门慈善网【家禽野兽】计划平特肖',
        url: 'https://csw703.chouhanbusiness.com:2053/#43229'
    },
    {
        title: '澳门大赢家【必中⑥肖】▲爆特诗句',
        url: 'https://d8y6j9.milmares.com:2053/#43229'
    },
    {
        title: '澳门71049【精选三肖】平特一肖中',
        url: 'https://01wz7w.harryenglishclub.com:2053/#am43229'
    },
    {
        title: '澳门27549【期期四肖】内幕２０码',
        url: 'https://p5j-7g2.mysantosha.com:2053/#am43229'
    },
    {
        title: '澳门41649【平特一尾】最强猛料■',
        url: 'https://426esl.xumutoutiao.com:2053/#am43229'
    },
    {
        title: '澳门62449【神秘平特】⑤肖中特火',
        url: 'https://k62j4w.riverbarfarms.com:2053/#am43229'
    },
    {
        title: '澳门24149【四肖主八码】万众瞩目',
        url: 'https://pst241.askaroundme.com:2053/#am43229'
    },
    {
        title: '澳门中特网【公式规律码】连准多期',
        url: 'https://33zt2w.sovaparents.com:2053/#am43229'
    },
    {
        title: '澳门中特网【公式规律码】连准多期',
        url: 'https://33zt2w.sovaparents.com:2053/#am43229'
    },
    {
        title: '澳门48449【单双单双王】计划平特',
        url: 'https://z48d4r.freetechebooks.com:2053/#am43229'
    },
    {
        title: '澳门74249【正版挂牌】爆六码中特',
        url: 'https://j7s4p2.pacificcrestbuildersinc.com:2053/#am43229'
    },
    {
        title: '澳门62549【现场直播】看开奖记录',
        url: 'https://x62j5b.kudosclimbing.com:2053/#am43229'
    },
    {
        title: '澳门64149【五肖主五码】攻平特肖',
        url: 'https://lg64-z1.greenboxfilms.com:2053/#am43229'
    },
    {
        title: '澳门33649【必中⑥肖】看精彩图纸',
        url: 'https://l3b-w36.bisabikinrumah.com:2053/#am43229'
    },

]

export const xianggang_shoulu = [
    {
        title: '正版一句爆特',
        url: 'https://jdb-903.scotibankpr.com:2053/#43229'
    },
    {
        title: '灭庄一肖一码',
        url: 'https://p3j8b9.elieshanelson.com:2053/#4322901'
    },
    {
        title: '二肖主博四码',
        url: 'https://f42hw7.adoptunderstand.com:2053/#9824906'
    },
    {
        title: '三字爆解特码',
        url: 'https://01wz7w.harryenglishclub.com:2053/#4322907'
    },
    {
        title: '内部推荐②肖',
        url: 'https://dd087lt.shtbmc.com:2090/#98249'
    },
    {
        title: '重磅三肖三码',
        url: 'https://d4d7q8.mingmuzhijia.com:2053/#43229'
    },
    {
        title: '平特复式连肖',
        url: 'https://j9c3-t2.strengthpurchase.com:2053/#98249d7'
    },
    {
        title: '美女重砸两肖',
        url: 'https://m27dl0.premiosnutrisenior.com:2053/#4322908'
    },
    {
        title: '今日头条②粒',
        url: 'https://2am8fy7.dpsxeozuylychguv.com:2058/#46249'
    },
    {
        title: '主攻三码中特',
        url: 'https://m2z7g8.3getonline.com:2053/#9824910'
    },
    {
        title: '注意:②肖中特',
        url: 'https://ss60z5.websdenegocios.com:2053/#4624917'
    },
    {
        title: '欲钱来这里找',
        url: 'http://w422a27s.whxzzs.com:12443/#98249'
    },
]

export const xianggang_tuku = [
    {
        title: '香港挂牌',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10355&type=1',
        bg: '#ffff00'
    },
    {
        title: '另版跑狗',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15855&type=1'
    },
    {
        title: '老版跑狗',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=12715&type=1',
        bg: '#c3147e'
    },
    {
        title: '凌波微步',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15825&type=1'
    },
    {
        title: '踏雪无痕',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15865&type=1',
        bg: '#009900'
    },
    {
        title: '正版四不像',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=12679&type=1'
    },
    {
        title: '神童平特',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10859&type=1'
    },
    {
        title: '金多宝传真',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=13013&type=1'
    },
    {
        title: '马会传真',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=416980&type=1'
    },
    {
        title: '铁算盘',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10592&type=1'
    },
    {
        title: '马会生活幽默',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15823&type=1'
    },
    {
        title: '脑筋急转弯',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10562&type=1'
    },
    {
        title: '挂牌玄机',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10870&type=1'
    },
    {
        title: '挂牌天书',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10873&type=1'
    },
    {
        title: '广州传真中特',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10616&type=1'
    },
    {
        title: '美女六肖图',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10858&type=1',
        bg: '#00ff00'
    },
    {
        title: '频果报',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10589&type=1'
    },
    {
        title: '东成西就',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15961&type=1'
    },
    {
        title: '六合头条',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=15831&type=1',
        bg: '#ff00ff'
    },
    {
        title: '八仙过海',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10667&type=1'
    },
    {
        title: '白小姐点金',
        url: 'https://nemoa.zaogradient.com:2053/pic1.php?name=xianggang&id=10247&type=1',
        bg: '#ff0000'
    },
]


export function generateRandomArray(min, max, length) {
    var randomArray = [];

    // 创建一个包含指定范围内所有可能值的数组
    var allValues = [];
    for (var i = min; i <= max; i++) {
        allValues.push(i);
    }

    // 从 allValues 数组中随机选择元素，并将其加入 randomArray 数组中
    for (var j = 0; j < length; j++) {
        var randomIndex = Math.floor(Math.random() * allValues.length);
        var randomValue = allValues.splice(randomIndex, 1)[0];
        if (randomValue < 10) {
            randomValue = '0' + randomValue
        } else {
            randomValue = '' + randomValue
        }
        randomArray.push(randomValue);
    }

    return randomArray;
}

export function isTimeWithinRange(startTime, endTime) {
    var currentDate = new Date();
    var start = new Date();
    var end = new Date();

    // 将传入的开始时间和结束时间转换为 Date 对象
    var startParts = startTime.split(":");
    start.setHours(parseInt(startParts[0]));
    start.setMinutes(parseInt(startParts[1]));
    start.setSeconds(parseInt(startParts[2]));

    var endParts = endTime.split(":");
    end.setHours(parseInt(endParts[0]));
    end.setMinutes(parseInt(endParts[1]));
    end.setSeconds(parseInt(endParts[2]));

    // 判断当前时间是否在开始时间和结束时间之间
    if (currentDate >= start && currentDate <= end) {
        return true; // 当前时间在传入的时间范围内
    } else {
        return false; // 当前时间不在传入的时间范围内
    }
}

export function checkTimeGreaterThanCurrent(time) {
    var currentTime = new Date();
    var parts = time.split(":");
    var inputTime = new Date();

    // 将传入的时间设置为与当前时间相同的日期
    inputTime.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
    inputTime.setHours(parseInt(parts[0]));
    inputTime.setMinutes(parseInt(parts[1]));
    inputTime.setSeconds(parseInt(parts[2]));

    // 判断传入时间是否大于当前时间
    if (inputTime > currentTime) {
        return true; // 传入时间大于当前时间
    } else {
        return false; // 传入时间不大于当前时间
    }
}

var timer
export function listenTime(targetTime, callback) {
    if (timer) clearInterval(timer)
    timer = setInterval(function () {
        var currentTime = new Date();
        // 获取当前时间的小时、分钟和秒
        var currentHours = currentTime.getHours();
        var currentMinutes = currentTime.getMinutes();
        var currentSeconds = currentTime.getSeconds();
        // 将目标时间字符串分割为小时、分钟和秒
        var targetParts = targetTime.split(":");
        var targetHours = parseInt(targetParts[0]);
        var targetMinutes = parseInt(targetParts[1]);
        var targetSeconds = parseInt(targetParts[2]);
        // 判断当前时间是否等于指定时间（精确到秒）
        if (
            currentHours === targetHours &&
            currentMinutes === targetMinutes &&
            currentSeconds === targetSeconds
        ) {
            callback(); // 当前时间等于指定时间，执行回调函数
        }
    }, 1000); // 每秒钟执行一次
}
