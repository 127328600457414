
<template>
  <div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
    <van-back-top right="16" offset="150" />
  </div>
</template>

<script setup>
</script>
<style scoped>
.logo {
  height: 30px;
  padding: 20px;
  will-change: filter;
  transition: filter 300ms;
}
.logo:hover {
  filter: drop-shadow(0 0 12px #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 12px #42b883aa);
}
</style>
